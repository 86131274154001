.modal {
    padding: 0 !important;
}

.modal-content {
    border-radius: 15px;
}

@media (min-width: 1200px) {
    .modal-xl {
        --bs-modal-width: 800px;
    }
}

.modal-success {
    color: white;
}

.modal-success .modal-content {
    background-color: rgb(33, 37, 41);
}

.modal-content-news {
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 15px;
}

#modal-online-shop {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 90%;
    min-width: 50vw;
    border-radius: 10px;
    padding-left: 0;
}

.online-shop-modal-body p {
    background-color: rgba(68, 68, 68, 0.3);
    backdrop-filter: blur(5px);
    padding: 5px 10px;
    border-radius: 15px;
}

.online-shop-modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
}

.online-shop-background-modal {
    background-image: url('../../../images/OnlineShopping/onlineShoppingBackground.webp');
    border: none !important;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    min-height: 70vh;
    min-width: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
}

.modal-one-time-home-background {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 94vh;
    background-color: rgba(192, 192, 192, 0.1);
}

#modal-one-time-home {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 90%;
    min-width: 70vw;
    padding-left: 0;
}

#modal-one-time-home-image {
    background-image: url('../../../images/Others/Sara-Luci-pandito.webp');
    min-height: 94vh;
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    border-radius: 15px;
}

#westfield-modal-image {
    background-image: url('../../../images/News/Gelateria_Westfield_2.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    min-height: 94vh;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.westfield-modal-title {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    gap: 25px;
}

.westfield-modal-title h1 {
    margin: 0;
    text-align: center;
}

.westfield-modal .modal-content{
    height: auto;
    min-height: 100%;
    display: flex;
    justify-content: space-around;
}

.westfield-modal-main .modal-header,
.westfield-modal-main .modal-body {
    background-color: rgba(54, 52, 48, 0.8);
    backdrop-filter: blur(4px);
    box-shadow: 1px 1px 10px rgb(188, 181, 181);
    display: inline-block;
    gap: 40px;
    border-radius: 15px;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
}

.westfield-modal-main .modal-header {
    margin-bottom: 100px;
}

.westfield-modal-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 94vh;
    border: none;
    gap: 30px;
    padding: 20px;
}

#opening-hours-image {
    background-image: url('../../../images/News/Opening_Hours_Big.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    min-height: 94vh;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.modal-info {
    flex: 0 !important;
}

.modal-info .p-container p {
    font-size: larger;
}

.westfield-modal-main .modal-header,
.westfield-modal-main .modal-footer {
    border: none;
}

.show {
    z-index: 2000 !important;
}

@keyframes growThenShrink {
   from {
    background-color: rgb(255, 119, 0);
   }
   0% { transform: scale(1); }
   50% { transform: scale(1.2); }
   100% { transform: scale(1); }
}

.verification-animation {
    display: inline-block;
    animation: growThenShrink 1.5s ease-in-out;
}

.new-verification-code {
    margin-top: 40px;
}

.dropdown-menu-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    margin-left: -80px !important;
}

.dropdown-menu-modal > div > .dropdown-menu {
    background-color: rgba(68, 68, 68, 0.7) !important;
    backdrop-filter: blur(1px);
}

.dropdown-menu-modal > div {
    background-color: rgba(68, 68, 68, 0.9) !important;
    border-radius: 10px;
    backdrop-filter: blur(1px);
}

@media (max-width: 1200px) {
    .modal-title-news h1 {
        font-size: 50px !important;
    }
    .modal-title-news img {
        width: 55px !important;
    }
    .modal-info .p-container p {
        font-size: larger;
    }
}

@media (max-width: 992px) {
    .modal-title-news h1 {
        font-size: 30px !important;
    }
    .modal-title-news img {
        width: 40px !important;
    }
    .dropdown-menu-modal {
        margin-left: -30px !important;
    }
    .first-time-modal-home-body .form .input {
        width: 70% !important;
    }
    .first-time-modal-home-body .p-container p {
        font-size: 15px !important;
    }
    .first-time-modal-home-body .p-container {
        padding: 20px 30px !important;
    }
    .modal-info .p-container p {
        font-size: large;
    }
}

@media (max-width: 650px) {
    .modal {
        max-height: 80vh;
        margin-top: 60px;
    }
    .modal-info .p-container p {
        font-size: medium;
    }
    .modal-info {
        margin-bottom: 20px;
    }
}

@media (max-width: 497px) {
    .modal {
        margin-top: 75px;
    }
    .modal-title-news h1 {
        font-size: 25px !important;
    }
    .modal-title-news img {
        width: 30px !important;
    }
    .modal-title-news .dropdown-lenguage-flag-box {
        width: 40px !important;
        gap: 1px;
    }
    .modal-title-news .dropdown-lenguage-flag-box p {
        font-size: 10px;
    }
    #modal-one-time-home-image {
        background-position: right center;
    }
}

.first-time-modal-home-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
}

.donate-box {
    text-align: center !important;
    justify-content: center !important;
}

.donate-gif {
    width: 5em;
    height: auto;
    mix-blend-mode: multiply;
}

.modal-succes-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    gap: 20px;
}

.modal-succes-body .white-button-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.first-time-modal-home-body .p-container {
    display: flex;
    flex-direction: column;
    justify-self: center !important;
    padding: 25px 40px;
    border-radius: 15px;
    background-color: rgba(236, 230, 217, 0.9);
    gap: 10px;
    max-width: 95%;
}

.first-time-modal-home-body .p-container p {
    font-size: 18px;
    color: black;
    margin: 0;
}

.first-time-modal-home-body .form {
    padding-top: 0;
    text-align: center;
    padding-top: 50px;
    color: beige;
}

.first-time-modal-home-body .form .input {
    width: 80%;
}

.first-time-modal-home-body .form .button {
    margin: 0;
    min-width: auto;
}

.first-time-modal-home-body a {
    text-decoration: none;
    text-align: justify;
}

.modal-title {
    font-size: 1.75rem !important;
    font-style: normal !important;
    font-optical-sizing: auto !important;
    color: white !important;
    font-weight: 400 !important;
}

.custom-header-home-modal{
    margin-top: 10em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-title-news {
    display: flex;
    justify-content: space-evenly;
    background-color: rgba(250, 235, 215, 0.8);
    padding: 10px 50px;
    border-radius: 15px;
    min-width: 100%;
}

.modal-title-news h1 {
    font-size: 80px;
    font-weight: 500;
    text-align: center;
    color: black !important;
    margin: 0;
}

.modal-title-news img {
    height: auto;
    width: 85px;
}

.modal-one-time-home-background .modal-header {
    justify-content: center;
    border-bottom: none !important;
    min-width: 95%;
}

.online-shop-background-modal .modal-header {
    background-color: rgba(68, 68, 68, 0.7) !important;
    backdrop-filter: blur(5px);
}

#about-modal {
    margin-top: 145px;
}

.gradient-line {
    background-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo);
    width: 80%;
    margin: 0 auto;
    border: 0;
    height: 1px;
    opacity: 1;
}

.spacer {
    height: 2em;
}

.modal-body h3 {
    font-size: 1.75rem !important;
}

b {
    font-weight: 600 !important;
}